import * as React from "react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Menu, MenuItem, ListItemButton, ListItemText, Tooltip, Accordion, AccordionSummary, AccordionDetails, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuIcon from '@mui/icons-material/Menu';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import Logout from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Home from "../pages/Home";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { getCurrentUser, signOut } from 'aws-amplify/auth';
import Avatar from "@mui/material/Avatar";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClientResources from "../pages/resources/ClientResources";
import ContactResources from "../pages/resources/ContactResources";
import LoginAs from "./LoginAs";
import { User } from "../models/users";
import { useGetUser } from "../hooks/useGetUser";
import PipelineResources from "../pages/resources/PipelineResources";
import PipelineView from "../pages/views/Pipeline";
import DealResources from "../pages/resources/DealResources";
import CommunicationResources from "../pages/resources/CommunicationResources";
import UserResources from "../pages/resources/UserResources";
import ApiDocs from "./ApiDocs";
import ApiIcon from '@mui/icons-material/Api';
import ScaleUnitResources from "../pages/resources/ScaleUnitResources";
import VoiceQueueResources from "../pages/resources/VoiceQueueResources";
import ScriptResources from "../pages/resources/ScriptResources";
import CampaignResources from "../pages/resources/CampaignResources";
import CampaignTemplatesResources from "../pages/resources/CampaignTemplatesResources";

const drawerWidth: number = 240;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const DashboardLayout = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [loginButtonDisplay, setLoginButtonDisplay] = useState('inline');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [openLoginAs, setOpenLoginAs] = useState(false);
  const [activeUser, setActiveUser] = useState<User>();
  const [loggedInUser, setLoggedInUser] = useState<User>();
  const [userID, setUserID] = useState<string>('');
  const [resourcesExpanded, setResourcesExpanded] = useState(false);
  const [viewsExpanded, setViewsExpanded] = useState(false);

  const { user, userError, userLoading } = useGetUser(userID, 1);

  useEffect(() => {
    verifyAuthentication()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (isAuthenticated) {
      setLoginButtonDisplay('none')
    } else {
      setLoginButtonDisplay('inline')
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (user) {
      if (user.id === userID) {
        setLoggedInUser(user)
      }

      console.log("User: ", user)
      setActiveUser(user)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (userError) {
      console.error("User Error: ", userError);
    }
  }, [userError]);

  async function verifyAuthentication() {
    try {
      const { username, userId, signInDetails } = await getCurrentUser();

      console.log("username", username);
      console.log("user id", userId);
      console.log("sign-in details", signInDetails);

      if (username) {
        setIsAuthenticated(true);
        setUserID(username)
      } else {
        navigate("/signin");
      }
    } catch (err) {
      navigate("/signin");
    }
  }

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = async () => {
    try {
      // sign out user
      await signOut();
      navigate("/signin");
    } catch (err) {
      console.error("Auth Error: ", err);
    }
  };

  const handleLogin = () => {
    console.log("Login button clicked")
    navigate("/signin");
  }

  const handleChangePassword = async () => {
    navigate("/changepassword");
  };

  const handleLoginAs = (user: User) => {
    setOpenLoginAs(false);
    setActiveUser(user);
  }

  const handleApiDocs = async () => {
    navigate("/apidocs");
    handleCloseUserMenu();
  };

  const resourcesAccordionChanged = () => {
    setResourcesExpanded(!resourcesExpanded)
  }

  const viewsAccordionChanged = () => {
    setViewsExpanded(!viewsExpanded)
  }

  const loginAsModalStyle = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: '#636363',
    border: '1px solid #ededed',
    boxShadow: 24,
    p: 1,
    height: 300,
  };

  const MainListItems = () => {
    return (
      <React.Fragment>
        <ListItemButton
          sx={{

          }}
          onClick={() => {
            navigate("/home");
          }}
        >
          <ListItemIcon>
            <HomeOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
        <Accordion expanded={resourcesExpanded} onChange={resourcesAccordionChanged}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} >
            Resources
          </AccordionSummary>
          <AccordionDetails>
            <List dense>
              <ListItemButton
                onClick={() => {
                  navigate("/resources/scaleunits");
                }}
              >
                <ListItemText primary="AI: Scale Units" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate("/resources/voicequeue");
                }}
              >
                <ListItemText primary="AI: Voice Queue" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate("/resources/scripts");
                }}
              >
                <ListItemText primary="AI: Voice Scripts" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate("/resources/campaign");
                }}
              >
                <ListItemText primary="Campaigns" />
              </ListItemButton>

              <ListItemButton
                onClick={() => {
                  navigate("/resources/campaign/templates");
                }}
              >
                <ListItemText primary="Campaign Templates" />
              </ListItemButton>

              <ListItemButton
                onClick={() => {
                  navigate("/resources/client");
                }}
              >
                <ListItemText primary="Clients" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate("/resources/communication");
                }}
              >
                <ListItemText primary="Communications" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate("/resources/contact");
                }}
              >
                <ListItemText primary="Contacts" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate("/resources/deal");
                }}
              >
                <ListItemText primary="Deals" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate("/resources/pipeline");
                }}
              >
                <ListItemText primary="Pipelines" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate("/resources/user");
                }}
              >
                <ListItemText primary="Users" />
              </ListItemButton>
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={viewsExpanded} onChange={viewsAccordionChanged}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Views
          </AccordionSummary>
          <AccordionDetails>
            <List sx={{ p: 0 }}>
              <ListItemButton sx={{ p: 0 }}
                onClick={() => {
                  navigate("/views/pipeline");
                }}
              >
                <ListItemText primary="Pipelines" sx={{ ml: 1 }} />
              </ListItemButton>
            </List>
          </AccordionDetails>
        </Accordion>
      </React.Fragment>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {isAuthenticated
        ? <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              component="h1"
              variant="h4"
              noWrap
              fontWeight="fontWeightMedium"
              sx={{ flexGrow: 1, padding: '10px' }}
            >
              High i.Q. CRM Test Client
            </Typography>
            {userLoading
              ? <Typography>Getting User...</Typography>
              : <Box>
                {activeUser && loggedInUser && activeUser.id === loggedInUser.id
                  ? <Typography>{activeUser?.firstName + " " + activeUser?.lastName}</Typography>
                  : <Typography>{loggedInUser?.firstName + " " + loggedInUser?.lastName} as {activeUser?.firstName + " " + activeUser?.lastName}</Typography>
                }
              </Box>
            }
            <Box sx={{ flexGrow: 0, marginRight: -3 }}>
              <Tooltip title="Open settings">
                <IconButton
                  size="medium"
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0, mx: 2 }}
                >
                  <Avatar alt="" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem key="apidocs" onClick={handleApiDocs}>
                  <ListItemIcon>
                    <ApiIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography textAlign="center">API Documentation</Typography>
                </MenuItem>

                <Divider />
                <MenuItem key="login-as" onClick={() => { setOpenLoginAs(true); handleCloseUserMenu() }}>
                  <ListItemIcon>
                    <LoginIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography textAlign="center">Login As...</Typography>
                </MenuItem>

                <MenuItem key="change-password" onClick={handleChangePassword}>
                  <ListItemIcon>
                    <LockResetOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography textAlign="center">Change Password</Typography>
                </MenuItem>

                <Divider />
                <MenuItem key="sign-out" onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  <Typography textAlign="center">Log Out</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        : <AppBar>
          <Toolbar>
            <Typography
              component="h1"
              variant="h5"
              noWrap
              fontWeight="fontWeightMedium"
              sx={{ flexGrow: 1, padding: '10px' }}
            >
              High i.Q. CRM Test Application
            </Typography>
            <IconButton onClick={handleLogin} sx={{ display: loginButtonDisplay, marginRight: -2 }}>
              <Stack direction="row">
                <Typography>Login</Typography>
                <LoginIcon />
              </Stack>
            </IconButton>
          </Toolbar>
        </AppBar>
      }
      {isAuthenticated
        ? <Drawer variant="permanent" open={open} sx={{}}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],

            }}
          >
            <IconButton type="button" onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav" sx={{}}>
            <MainListItems />
          </List>
        </Drawer>
        : <Box>

        </Box>
      }
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/resources/voicequeue" element={<VoiceQueueResources activeUser={activeUser || {} as User} />} />
          <Route path="/resources/campaign" element={<CampaignResources activeUser={activeUser || {} as User} />} />
          <Route path="/resources/campaign/templates" element={<CampaignTemplatesResources activeUser={activeUser || {} as User} />} />
          <Route path="/resources/client" element={<ClientResources />} />
          <Route path="/resources/communication" element={<CommunicationResources activeUser={activeUser || {} as User} />} />
          <Route path="/resources/contact" element={<ContactResources activeUser={activeUser || {} as User} />} />
          <Route path="/resources/deal" element={<DealResources activeUser={activeUser || {} as User} />} />
          <Route path="/resources/pipeline" element={<PipelineResources activeUser={activeUser || {} as User} />} />
          <Route path="/resources/scaleunits" element={<ScaleUnitResources activeUser={activeUser || {} as User} />} />
          <Route path="/resources/scripts" element={<ScriptResources activeUser={activeUser || {} as User} />} />
          <Route path="/resources/user" element={<UserResources activeUser={activeUser || {} as User} />} />
          <Route path="/views/pipeline" element={<PipelineView activeUser={activeUser || {} as User} />} />
          <Route path="/apidocs" element={<ApiDocs />} />
        </Routes>
      </Box>
      <Modal open={openLoginAs} onClose={() => setOpenLoginAs(false)}>
        <Box sx={loginAsModalStyle} >
          <LoginAs onLoginUserChanged={handleLoginAs} onClose={() => setOpenLoginAs(false)} />
        </Box>
      </Modal>
    </Box>
  );
};

export default DashboardLayout;
