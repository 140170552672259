import { BaseResponse, DataStoreBase } from "./base";

export interface CampaignsResponse extends BaseResponse {
    status: string,
    requestID: string,
    timestamp: string,
    errorMessage: string,
    page: string,
    campaigns: Campaign[],
}

export interface Campaign extends DataStoreBase {
    clientID: string,
    name: string,
    description: string,
    startDate: string,
    endDate: string,
    status: CampaignStatus,
    createdBy: string,
    steps: CampaignStep[],
    runWindow: CampaignRunWindow,
    dripRule: CampaignDripRule,
}

export interface CampaignRunWindow {
    startTime: string,
    endTime: string,
    days: number[],
    skipDates: string[],
}

// export interface CampaignDeal extends DataStoreBase {
//     dealID: string,
//     activityState: ActivityState,
//     currentStepID: string,
//     busyCount: number,
//     noAnswerCount: number,
//     voicemailCount: number,
//     ownerID: string,
// }

// export interface CampaignQueuedDeal {
//     dealID: string,
//     queuedAt: string,
// }

export interface CampaignStep extends DataStoreBase {
    name: string,
    description: string,
    type: CampaignStepType,
    outcomes: CampaignStepOutcome[],
    configuration: CampaignStepConfiguration,
    Layout: CampaignStepLayout
}

export interface CampaignStepLayout {
    position: StepLayoutPosition
}

export interface StepLayoutPosition {
    x: number,
    y: number,
}
export interface CampaignStepConfiguration {
    phoneConfiguration?: CampaignStepPhoneConfiguration,
    emailConfiguration?: CampaignStepEmailConfiguration,
    smsConfiguration?: CampaignStepSMSConfiguration,
    waitConfiguration?: CampaignStepWaitConfiguration,
    webhookConfiguration?: CampaignStepWebhookConfiguration,
    setTagConfiguration?: CampaignStepSetTagConfiguration,
    tagConditionConfiguration?: CampaignStepTagConditionConfiguration,
}

export interface CampaignStepEmailConfiguration {
    subjectTemplate: string,
    bodyTemplate: string,
}

export interface CampaignStepPhoneConfiguration {
    voiceScriptID: string,
}

export interface CampaignStepSMSConfiguration {
    smsMessageTemplate: string,
}

export interface CampaignStepWaitConfiguration {
    waitRules: CampaignWaitRule[],
}

export interface CampaignStepWebhookConfiguration {
    url: string,
    method: string,
    headers: {[key: string]: string},
    body: string,
}

export interface CampaignStepSetTagConfiguration {
    tag: string,
}

export interface CampaignStepTagConditionConfiguration {
    tag: string,
    operator: ComparisonOperator,
    value: string,
}

export interface CampaignDripRule {
    maxDailyCount: number,
    maxHourlyCount: number,
    maxMinuteCount: number,
}

export interface CampaignDripStatus {
    dailyCount: number,
    hourlyCount: number,
    lastUpdated: string,
}

// export interface CampaignAudience {
//     campaignID: string,
//     clientID: string,
//     deals: CampaignDeal[],
//     queuedDeals: CampaignQueuedDeal[],
//     dripStatus: CampaignDripStatus,
//     status: CampaignAudienceStatus,
// }

export interface CampaignStepOutcomeAssociation {
    step: CampaignStepType,
    outcomes: OutcomeType[],
}

export interface CampaignWaitRule {
    countMin: number,
    countMax: number,
    waitDuration: number,
}

export interface CampaignStepOutcome {
    type: OutcomeType,
    nextStepID: string,
}

export enum CampaignStatus {
    Active = "active",
    Archived = "archived",
    Planned = "planned",
    Completed = "completed",
    Paused = "paused",
}

export enum ActivityState {
    Active = "active",
    Archived = "archived",
    Planned = "planned",
    Completed = "completed",
    Paused = "paused",
}

export enum CampaignAudienceStatus {
    Active = "active",
    Archived = "archived",
    Completed = "completed",
    Paused = "paused",
}

export enum CampaignStepType {
    Phone = "phone",
    Email = "email",
    SMS = "sms",
    Wait = "wait",
    End = "end",
    Start = "start",
    Webhook = "webhook",
    SetTag = "setTag",
    TagCondition = "tagCondition",
}

export enum OutcomeType {
    PhoneBusy = "phone_busy",
    PhoneNoAnswer = "phone_no_answer",
    Voicemail = "voicemail",
    Disconnected = "disconnected",
    WrongNumber = "wrong_number",
    NotInterested = "not_interested",
    CallBack = "call_back",
    MeetingScheduled = "meeting_scheduled",
    MaxAttempts = "max_attempts",
    Success = "success",
    Failure = "failure",
    True = "true",
    False = "false",
}

export enum ComparisonOperator {
    Equal = "eq",
    NotEqual = "ne",
    LessThan = "lt",
    LessThanOrEqual = "le",
    GreaterThan = "gt",
    GreaterThanOrEqual = "ge",
    Contains = "contains",
}

export interface DealCampaign {
    campaignID: string,
    createdAt: string,
    updatedAt: string,
    tags: string[],
    activityState: ActivityState,
    currentStepID: string,
    busyCount: number,
    noAnswerCount: number,
    voicemailCount: number,
}