import { Alert, Box, Grid, IconButton, Link, Modal, Paper, Snackbar, Stack, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import LoadingProgress from "../../components/LoadingProgress";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import JSONInput from "../../components/JSONInput";
import  NoData from "../../components/NoData";
import { APIEndpoint, EndpointType, httpDelete, httpPost, httpPut } from "../../utils/apiService";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from "../../components/ConfirmDialog";
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { User } from "../../models/users";
import CopyToClipboard from "../../components/CopyToClipboard";
import { useGetVoiceScripts } from "../../hooks/useGetVoiceScripts";
import { AIScript, VoiceScript, VoiceScriptsResponse } from "../../models/scripts";

interface Props {
    activeUser: User
}

export default function ScriptResources(props: Props) {
    // State
    const [page, setPage] = useState("");
    const [refreshToken, setRefreshToken] = useState(0);
    const [openJsonInput, setOpenJsonInput] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMsg, setSnackbarMsg] = useState("")
    const [jsonInputData, setJsonInputData] = useState("")
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [clientID, setClientID] = useState("")

    // Hooks
    const {voiceScripts, voiceScriptsError, voiceScriptsLoading, voiceScriptsPage} = useGetVoiceScripts(clientID, page, refreshToken);

    const columns = [
        { field: 'id', headerName: 'ID', width: 160, renderCell: (params: GridRenderCellParams) => {
            return        <Box><Link
            component="button"
            variant="body2"
            onClick={() => {
              setJsonInputData(JSON.stringify(params.row));
              setOpenJsonInput(true);
            }}
            >
            {params.value}
          </Link><CopyToClipboard value={params.value} /> </Box>
        } },
        { field: 'createdAt', headerName: 'Created At', width: 200 },
        { field: 'name', headerName: 'Name', width: 120 },
        { field: 'script', headerName: 'Script', width: 120 },
        { field: 'flow', headerName: 'Flow', width: 120 },
        { field: 'openingMessage', headerName: 'Opening Msg', width: 120 },
        { field: 'prompt', headerName: 'Prompt', width: 120 },
        { field: 'knowledgeBase', headerName: 'Knowledge Base', width: 120 },
    ];

    useEffect(() => {
        setRefreshToken(1);
    }, []);

    useEffect(() => {
        if (props.activeUser && props.activeUser.clients && props.activeUser.clients.length > 0) {
            setClientID(props.activeUser.clients[0].clientID);
        }
    }, [props.activeUser]);

    const handleJsonSave = (json: string) => {
        console.log("saving json: ", json)
        setOpenJsonInput(false);

        const script = JSON.parse(json) as VoiceScript;
        if (script.id === "") {
            createItem(json)
        } else {
            updateItem(json, script.id)
        }
    }

    const createItem = (json: string) => {
        const apiURL =  APIEndpoint(EndpointType.voicescripts)
        httpPost(apiURL, json)
        .then((data) => {
            const response = data as VoiceScriptsResponse;
            if (response.status === "success") {
                setSnackbarMsg("Created successfully")
                setOpenSnackbar(true)
                setRefreshToken(refreshToken + 1)
            } else if (response.status === "error") {
                setSnackbarMsg(response.errorMessage)
                setOpenSnackbar(true)
            }
            
        })
        .catch((error) => { 
            console.log("Creation error: " + error.message)
        });  
    }

    const updateItem = (json: string, id: string) => {
        const apiURL =  APIEndpoint(EndpointType.voicescripts) + `/${id}?client-id=${clientID}`
        httpPut(apiURL, json)
        .then((data) => {
            const response = data as VoiceScriptsResponse;
            if (response.status === "success") {
                setSnackbarMsg("Updated successfully")
                setOpenSnackbar(true)
                setRefreshToken(refreshToken + 1)
            } else if (response.status === "error") {
                setSnackbarMsg(response.errorMessage)
                setOpenSnackbar(true)
            }
            
        })
        .catch((error) => { 
            console.log("Update error: " + error.message)
        });  
    }

    const promptForDelete = () => {
        if (selectedItems.length === 0) {
            setSnackbarMsg("No items selected for deletion")
            setOpenSnackbar(true)
        } else {
            setOpenConfirmDialog(true)
        }
    }

    const deleteItem = (id: string) => {
        const apiURL =  APIEndpoint(EndpointType.voicescripts) + `/${id}`
        httpDelete(apiURL)
        .then((data) => {
            const response = data as VoiceScriptsResponse;
            if (response.status === "success") {
                setSnackbarMsg("Deleted successfully")
                setOpenSnackbar(true)
                setRefreshToken(refreshToken + 1)
            } else if (response.status === "error") {
                setSnackbarMsg(response.errorMessage)
                setOpenSnackbar(true)
            }
            
        })
        .catch((error) => { 
            console.log("Deletion error: " + error.message)
        });  
    }

    const newVoiceScript = ():string => {
        const aiScript: AIScript = {
            name: "",
            script: "",
            flow: "",
            openingMessage: "",
            prompt: "",
            knowledgeBase: ""
        }
        const script: VoiceScript = {
            id: "",
            clientID: "",
            voiceScript: aiScript,
            textScript: aiScript,
            phoneNumber: ""
        }

        return JSON.stringify(script)
    }

    const handleAddItemClose = () => {
        setOpenJsonInput(false);
    };

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    const onConfirmDialogClicked = (confirmed: boolean) => {
        setOpenConfirmDialog(false);
        if (confirmed) {
            selectedItems.forEach(selectedItemID => {
                deleteItem(selectedItemID)
            });
        }
    }

    const snackbarAction = (
        <Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </Fragment>
    );

    const modalStyle = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: '#636363',
        border: '1px solid #ededed',
        boxShadow: 24,
        p: 1,
        height: 800,
    };

    return (
        <Paper sx={{ margin: 1 }}>
            <Stack direction="row" spacing={1} alignItems="flex-start" justifyContent="space-between">
                <Typography variant="h5" sx={{ ml: 1 }}>Voice Script Resources</Typography>
                <Stack direction="row" spacing="1">
                    <IconButton aria-label="New" size="medium" type="button" onClick={() => {setJsonInputData(newVoiceScript()); setOpenJsonInput(true)}} sx={{ height: 40, marginTop: 1 }} >
                        <AddCircleOutlineIcon  sx={{color:"#00ff00"}}  />
                    </IconButton>
                    <IconButton aria-label="Delete" size="medium" type="button" onClick={() => promptForDelete()} sx={{ height: 40, marginTop: 1 }} >
                        <DeleteIcon sx={{color:"#ff0000"}} />
                    </IconButton>
                    {voiceScriptsPage && voiceScriptsPage !== "" &&
                        <IconButton aria-label="Refresh" size="medium" type="button" onClick={() => setPage(voiceScriptsPage)} sx={{ height: 40, marginTop: 1 }} >
                            <SkipNextIcon  sx={{color:"#ffff00"}}  />
                        </IconButton>
                    }
                    <IconButton aria-label="Refresh" size="medium" type="button" onClick={() => setRefreshToken(refreshToken + 1)} sx={{ height: 40, marginTop: 1 }} >
                        <RefreshIcon  sx={{color:"#00ffff"}}  />
                    </IconButton>
                </Stack>
            </Stack>
            {voiceScriptsLoading && <LoadingProgress title="Getting Voice Scripts..." />}
            {voiceScriptsError && <Alert severity="error" sx={{m:1}}>{voiceScriptsError}</Alert>}

            <Grid sx={{height:"88vh"}}>
                <DataGrid rows={voiceScripts || []} columns={columns} sx={{ mt: 2 }} slots={{noRowsOverlay: NoData}} checkboxSelection
                                        onRowSelectionModelChange={(ids) => {
                                            const selectedIDs = new Set(ids);
                                            const arrayIDs: string[] = [];
                                            selectedIDs.forEach(v => arrayIDs.push(v.toString()));
                                            setSelectedItems(arrayIDs)
                                          }} />
            </Grid>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMsg}
                action={snackbarAction}
            />
            <Modal open={openJsonInput} onClose={handleAddItemClose}>
                <Box sx={modalStyle} >
                    <JSONInput title="Create New Voice Script" json={jsonInputData} onSave={handleJsonSave} />
                </Box>
            </Modal>
            <ConfirmDialog open={openConfirmDialog} title="Delete Voice Script" message="Are you sure you want to delete the selected items?" confirmText="Yes" denyText="No" onConfirm={onConfirmDialogClicked} />
        </Paper>
    )
}

