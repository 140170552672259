import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { Campaign, CampaignsResponse } from '../models/campaigns';

export const useGetCampaigns = (clientID: string, page: string, getTemplates: boolean, refreshToken: number) => {
    const [campaigns, setCampaigns] = useState<Campaign[]>()
    const [campaignsPage, setCampaignsPage] = useState("")
    const [campaignsError, setCampaignsError] = useState("")
    const [campaignsLoading, setCampaignsLoading] = useState(false)

    useEffect(() => {
        if (refreshToken === 0) return;

        setCampaigns([])
        setCampaignsPage("")
        setCampaignsError("")
        setCampaignsLoading(true)

        let apiURL =  APIEndpoint(EndpointType.campaigns);

        if (getTemplates) {
            apiURL += "/templates"
        }

        const pageParam = page === "" ? "" : `&page=${page}`
        const params = `?client-id=${clientID}${pageParam}`

        const url = `${apiURL}${params}`
        console.log(url)
        httpGet(url)
            .then((data) => {
                const response = data as CampaignsResponse;
                
                if (response.status === "error") {
                    setCampaignsError(response.errorMessage)
                } else {
                    if (response.campaigns) {
                        setCampaigns(response.campaigns);
                        setCampaignsPage(response.page);
                    } 
                }
            })
            .catch((error) => setCampaignsError(error.message || "Error fetching campaigns"))
            .finally(() => setCampaignsLoading(false))

    }, [clientID, refreshToken, page, getTemplates]);

    return { campaigns, campaignsPage, campaignsError, campaignsLoading};
};
